	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/06/2016 15:09
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
}

.flaticon-add:before { content: "\f100"; }
.flaticon-adding-to-the-cloud:before { content: "\f101"; }
.flaticon-alarm-black-filled-bell-symbol:before { content: "\f102"; }
.flaticon-analogic-video-camera:before { content: "\f103"; }
.flaticon-anchor:before { content: "\f104"; }
.flaticon-archive-filled-box:before { content: "\f105"; }
.flaticon-arroba-sign:before { content: "\f106"; }
.flaticon-arrow-angle-pointing-to-left:before { content: "\f107"; }
.flaticon-arrow-button-to-begin:before { content: "\f108"; }
.flaticon-arrow-pointing-to-right:before { content: "\f109"; }
.flaticon-arrow-pointing-to-upper-left-to-a-circle:before { content: "\f10a"; }
.flaticon-arrow-to-last-track:before { content: "\f10b"; }
.flaticon-ascending-line-graphic-symbol:before { content: "\f10c"; }
.flaticon-attachment-paperclip-symbol-of-straight-lines-with-rounded-angles:before { content: "\f10d"; }
.flaticon-audio-amplification-speaker-tool-silhouette:before { content: "\f10e"; }
.flaticon-audio-speaker-on:before { content: "\f10f"; }
.flaticon-back-arrow-to-first-track:before { content: "\f110"; }
.flaticon-bag-filled-tool:before { content: "\f111"; }
.flaticon-bars-of-descending-graphic:before { content: "\f112"; }
.flaticon-binary-code-with-zeros-and-one:before { content: "\f113"; }
.flaticon-black-hand-with-finger-pointing-down:before { content: "\f114"; }
.flaticon-black-text-page-to-swipe-horizontally:before { content: "\f115"; }
.flaticon-blackboard:before { content: "\f116"; }
.flaticon-blackboard-with-text-lines:before { content: "\f117"; }
.flaticon-book:before { content: "\f118"; }
.flaticon-book-opened-filled-reading-tool:before { content: "\f119"; }
.flaticon-bookmark-for-favorites:before { content: "\f11a"; }
.flaticon-bookmarked-filled-square:before { content: "\f11b"; }
.flaticon-broken-heart-divided-in-two-parts:before { content: "\f11c"; }
.flaticon-broken-web-link:before { content: "\f11d"; }
.flaticon-bug:before { content: "\f11e"; }
.flaticon-burn:before { content: "\f11f"; }
.flaticon-business-bars-graphic:before { content: "\f120"; }
.flaticon-calendar-to-organize-dates:before { content: "\f121"; }
.flaticon-car-fill-from-frontal-view:before { content: "\f122"; }
.flaticon-card-with-images-data:before { content: "\f123"; }
.flaticon-cascade-rectangular-rellena:before { content: "\f124"; }
.flaticon-cat-black-face:before { content: "\f125"; }
.flaticon-cat-face-outline:before { content: "\f126"; }
.flaticon-centered-text-of-alignment-button:before { content: "\f127"; }
.flaticon-chat-of-two-filled-rectangular-speech-bubbles-of-different-sizes:before { content: "\f128"; }
.flaticon-chat-of-two-rounded-rectangular-filled-speech-bubbles:before { content: "\f129"; }
.flaticon-checked-box:before { content: "\f12a"; }
.flaticon-checked-filled-list:before { content: "\f12b"; }
.flaticon-cinema-film-wheel-with-holes:before { content: "\f12c"; }
.flaticon-circular-arrows:before { content: "\f12d"; }
.flaticon-circular-filled-shape:before { content: "\f12e"; }
.flaticon-circular-graphic-of-percentages-partition-of-one-and-three-quarters-parts:before { content: "\f12f"; }
.flaticon-clapper:before { content: "\f130"; }
.flaticon-clipboard-with-left-arrow:before { content: "\f131"; }
.flaticon-clock-circular-gross-outline-of-time:before { content: "\f132"; }
.flaticon-clock-with-refresh-circular-arrow:before { content: "\f133"; }
.flaticon-cloud-black-shape:before { content: "\f134"; }
.flaticon-cloud-filled-shape-with-minus-sign:before { content: "\f135"; }
.flaticon-code-filled-file-with-two-arrows-to-right-and-left:before { content: "\f136"; }
.flaticon-code-programing-symbol:before { content: "\f137"; }
.flaticon-commercial-credit-card:before { content: "\f138"; }
.flaticon-commercial-filled-card:before { content: "\f139"; }
.flaticon-computer-square-button-symbol-with-circles-and-lines:before { content: "\f13a"; }
.flaticon-configuration-with-gear:before { content: "\f13b"; }
.flaticon-connection-of-circles-line-and-arrow:before { content: "\f13c"; }
.flaticon-console-of-three-switches:before { content: "\f13d"; }
.flaticon-contacts-book:before { content: "\f13e"; }
.flaticon-contrast-of-monitor:before { content: "\f13f"; }
.flaticon-contrast-square-button:before { content: "\f140"; }
.flaticon-copy-symbol-of-two-filled-paper-sheets:before { content: "\f141"; }
.flaticon-credit-card-back:before { content: "\f142"; }
.flaticon-credit-card-filled-back:before { content: "\f143"; }
.flaticon-cross-in-square:before { content: "\f144"; }
.flaticon-cross-like-opened-scissor:before { content: "\f145"; }
.flaticon-cross-sign:before { content: "\f146"; }
.flaticon-cube-box:before { content: "\f147"; }
.flaticon-curved-arrow-pointing-prohibition-symbol:before { content: "\f148"; }
.flaticon-database:before { content: "\f149"; }
.flaticon-database-digital-tool:before { content: "\f14a"; }
.flaticon-descending-straight-filled-arrow:before { content: "\f14b"; }
.flaticon-design-button-of-curved-and-straight-lines:before { content: "\f14c"; }
.flaticon-design-distribution-of-elements-of-an-article:before { content: "\f14d"; }
.flaticon-design-structure-button-with-text-and-image-alignment-option:before { content: "\f14e"; }
.flaticon-design-with-header-divided-in-three-areas-in-square-button:before { content: "\f14f"; }
.flaticon-diagonal-rounded-rectangles:before { content: "\f150"; }
.flaticon-diamond-shape-symbol-with-black-and-white-half-parts:before { content: "\f151"; }
.flaticon-dice-square-with-a-dot-or-button-with-a-circle-inside:before { content: "\f152"; }
.flaticon-directional-left-arrow-symbol:before { content: "\f153"; }
.flaticon-disabled-person-signal-of-black-square:before { content: "\f154"; }
.flaticon-disc-perspective:before { content: "\f155"; }
.flaticon-diskette:before { content: "\f156"; }
.flaticon-dollar-currency-symbol:before { content: "\f157"; }
.flaticon-double-arrow:before { content: "\f158"; }
.flaticon-double-arrow-cross-of-shuffle:before { content: "\f159"; }
.flaticon-double-arrows-symbol-pointing-up-and-down:before { content: "\f15a"; }
.flaticon-double-diagonal-arrow:before { content: "\f15b"; }
.flaticon-double-down-arrows:before { content: "\f15c"; }
.flaticon-double-right-arrows-angles:before { content: "\f15d"; }
.flaticon-double-up-and-down-arrow-symbol:before { content: "\f15e"; }
.flaticon-double-up-arrow:before { content: "\f15f"; }
.flaticon-down-arrow:before { content: "\f160"; }
.flaticon-down-arrow-1:before { content: "\f161"; }
.flaticon-down-arrow-button-on-horizontal-line:before { content: "\f162"; }
.flaticon-down-arrow-filled-circular-button:before { content: "\f163"; }
.flaticon-down-arrow-on-horizontal-line:before { content: "\f164"; }
.flaticon-down-filled-triangular-arrow:before { content: "\f165"; }
.flaticon-down-left-arrow-outline-of-triangular-shape:before { content: "\f166"; }
.flaticon-down-line-graphic:before { content: "\f167"; }
.flaticon-down-right-arrow-pointing-a-circle:before { content: "\f168"; }
.flaticon-down-with-gross-arrow:before { content: "\f169"; }
.flaticon-download-from-the-filled-cloud:before { content: "\f16a"; }
.flaticon-download-to-inbox:before { content: "\f16b"; }
.flaticon-dreaming-in-cloud:before { content: "\f16c"; }
.flaticon-e-book-with-right-arrow:before { content: "\f16d"; }
.flaticon-eject:before { content: "\f16e"; }
.flaticon-eject-1:before { content: "\f16f"; }
.flaticon-email-in-filled-back-opened-envelope-with-a-letter-inside:before { content: "\f170"; }
.flaticon-equal:before { content: "\f171"; }
.flaticon-exchanging-data:before { content: "\f172"; }
.flaticon-exclamation-sign-in-a-circle:before { content: "\f173"; }
.flaticon-exclamation-sign-in-circular-right-arrow:before { content: "\f174"; }
.flaticon-eye-opened-symbol-with-an-eyebrow:before { content: "\f175"; }
.flaticon-fast-backward-double-left-arrow-symbol:before { content: "\f176"; }
.flaticon-favourites-filled-star-symbol:before { content: "\f177"; }
.flaticon-file-filled-paper-sheet:before { content: "\f178"; }
.flaticon-file-filled-paper-symbol-with-right-arrow:before { content: "\f179"; }
.flaticon-file-of-acrobat-reader:before { content: "\f17a"; }
.flaticon-fill-design-with-color-of-a-paint-cube:before { content: "\f17b"; }
.flaticon-filter-filled-tool-symbol:before { content: "\f17c"; }
.flaticon-fire-extinguisher-filled-tool:before { content: "\f17d"; }
.flaticon-first-aid-handbag:before { content: "\f17e"; }
.flaticon-first-track-multimedia-button:before { content: "\f17f"; }
.flaticon-flag-black-shape-on-a-pole:before { content: "\f180"; }
.flaticon-flag-filled-tool-on-a-pole:before { content: "\f181"; }
.flaticon-flag-triangle-pointing-left:before { content: "\f182"; }
.flaticon-focus-with-a-circle-with-four-lines:before { content: "\f183"; }
.flaticon-folder-filled-computer-tool:before { content: "\f184"; }
.flaticon-folder-filled-tool-with-right-arrow:before { content: "\f185"; }
.flaticon-folder-filled-with-tabs:before { content: "\f186"; }
.flaticon-footprint:before { content: "\f187"; }
.flaticon-forefinger-of-filled-hand-pointing-up-like-an-arrow:before { content: "\f188"; }
.flaticon-forefinger-pointing-to-right-from-filled-hand-shape:before { content: "\f189"; }
.flaticon-forward-button-for-multimedia:before { content: "\f18a"; }
.flaticon-four-dots-horizontally-aligned-as-a-line:before { content: "\f18b"; }
.flaticon-four-graphics-symbol-of-interface:before { content: "\f18c"; }
.flaticon-four-lines:before { content: "\f18d"; }
.flaticon-four-squares-button:before { content: "\f18e"; }
.flaticon-games-control:before { content: "\f18f"; }
.flaticon-giftbox-filled:before { content: "\f190"; }
.flaticon-global-grid-globe-symbol:before { content: "\f191"; }
.flaticon-global-planetary-sphere-with-continent:before { content: "\f192"; }
.flaticon-graduate-hat:before { content: "\f193"; }
.flaticon-hand-filled-silhouette-with-flexed-fingers:before { content: "\f194"; }
.flaticon-hand-silhouette-of-a-man:before { content: "\f195"; }
.flaticon-hand-with-forefinger-pointing-to-left:before { content: "\f196"; }
.flaticon-happy-filled-face:before { content: "\f197"; }
.flaticon-headphones:before { content: "\f198"; }
.flaticon-home:before { content: "\f199"; }
.flaticon-home-black-building:before { content: "\f19a"; }
.flaticon-hr:before { content: "\f19b"; }
.flaticon-image-of-mountains:before { content: "\f19c"; }
.flaticon-image-with-frame:before { content: "\f19d"; }
.flaticon-images-or-windows-rectangular-filled-shapes:before { content: "\f19e"; }
.flaticon-infinite-or-bow:before { content: "\f19f"; }
.flaticon-information-in-circular-button:before { content: "\f1a0"; }
.flaticon-information-letter-sign-or-person-shape-from-side-view:before { content: "\f1a1"; }
.flaticon-interface-symbol-of-three-circles-with-lines-union:before { content: "\f1a2"; }
.flaticon-invisible-person-of-clothes:before { content: "\f1a3"; }
.flaticon-italic:before { content: "\f1a4"; }
.flaticon-jar-with-stripes:before { content: "\f1a5"; }
.flaticon-keyboard-buttons-or-visualization-button-of-nine-squares:before { content: "\f1a6"; }
.flaticon-keyboard-computer-hardware-accesory:before { content: "\f1a7"; }
.flaticon-last-track-multimedia-button-with-arrow:before { content: "\f1a8"; }
.flaticon-left-arrow:before { content: "\f1a9"; }
.flaticon-left-arrow-in-circular-button:before { content: "\f1aa"; }
.flaticon-left-curved-arrow:before { content: "\f1ab"; }
.flaticon-left-filled-arrow-triangle:before { content: "\f1ac"; }
.flaticon-left-quote:before { content: "\f1ad"; }
.flaticon-left-text-alignment-option:before { content: "\f1ae"; }
.flaticon-left-with-straight-arrow:before { content: "\f1af"; }
.flaticon-less-zoom-with-filled-magnifier:before { content: "\f1b0"; }
.flaticon-letter-option-button:before { content: "\f1b1"; }
.flaticon-letter-s-between-straight-parenthesis-symbol:before { content: "\f1b2"; }
.flaticon-libra-justice-balanced-scale-symbol:before { content: "\f1b3"; }
.flaticon-library-books-or-four-vertical-lines:before { content: "\f1b4"; }
.flaticon-library-of-three-filled-vertical-equal-bars:before { content: "\f1b5"; }
.flaticon-lifeline-irregular-zig-zag-shape-sign:before { content: "\f1b6"; }
.flaticon-lightbulb-filled-tool:before { content: "\f1b7"; }
.flaticon-lighter-point-filled-shape:before { content: "\f1b8"; }
.flaticon-lightning-bolt-filled-shape-of-weather:before { content: "\f1b9"; }
.flaticon-like-of-filled-heart:before { content: "\f1ba"; }
.flaticon-links-of-rectangular-straight-shape-in-horizontal-position:before { content: "\f1bb"; }
.flaticon-liquid-drop-falling-of-a-cup:before { content: "\f1bc"; }
.flaticon-list-of-three-items-with-squares-and-lines:before { content: "\f1bd"; }
.flaticon-locked-black-rectangular-padlock:before { content: "\f1be"; }
.flaticon-logout:before { content: "\f1bf"; }
.flaticon-magic-wand:before { content: "\f1c0"; }
.flaticon-magnet-rotated-tool-symbol:before { content: "\f1c1"; }
.flaticon-mail-closed-filled-back-envelope:before { content: "\f1c2"; }
.flaticon-male-hand-shape:before { content: "\f1c3"; }
.flaticon-man-and-target:before { content: "\f1c4"; }
.flaticon-man-close-up:before { content: "\f1c5"; }
.flaticon-man-male-hand-silhouette-with-thumb-down:before { content: "\f1c6"; }
.flaticon-map-black-triptych-folded-paper:before { content: "\f1c7"; }
.flaticon-map-filled-with-a-placeholder:before { content: "\f1c8"; }
.flaticon-mask-or-apron-fill-shape:before { content: "\f1c9"; }
.flaticon-menu-button-of-three-horizontal-lines:before { content: "\f1ca"; }
.flaticon-message-of-filled-rectangular-speech-bubble:before { content: "\f1cb"; }
.flaticon-message-of-rounded-rectangular-filled-speech-bubble:before { content: "\f1cc"; }
.flaticon-metro-transport-signal-down-here-with-an-arrow:before { content: "\f1cd"; }
.flaticon-microphone:before { content: "\f1ce"; }
.flaticon-microphone-filled-tool:before { content: "\f1cf"; }
.flaticon-microphone-for-voice:before { content: "\f1d0"; }
.flaticon-microscope:before { content: "\f1d1"; }
.flaticon-minus:before { content: "\f1d2"; }
.flaticon-minus-in-square-outlined-interface-button:before { content: "\f1d3"; }
.flaticon-mobile-phone:before { content: "\f1d4"; }
.flaticon-more-three-dots-button:before { content: "\f1d5"; }
.flaticon-more-with-three-dots-button:before { content: "\f1d6"; }
.flaticon-mouse-filled-shape:before { content: "\f1d7"; }
.flaticon-mouse-filled-tool:before { content: "\f1d8"; }
.flaticon-music-note:before { content: "\f1d9"; }
.flaticon-musical-filled-note:before { content: "\f1da"; }
.flaticon-mute-speaker:before { content: "\f1db"; }
.flaticon-new-email-filled-back-envelope:before { content: "\f1dc"; }
.flaticon-note-filled-square-with-a-pencil:before { content: "\f1dd"; }
.flaticon-notebook-filled-with-text-lines:before { content: "\f1de"; }
.flaticon-notebook-of-filled-pages-and-spring:before { content: "\f1df"; }
.flaticon-numbered-items-button:before { content: "\f1e0"; }
.flaticon-opposite-arrows-of-angles-pointing-to-sides:before { content: "\f1e1"; }
.flaticon-paint-roller-of-filled-silhouette:before { content: "\f1e2"; }
.flaticon-paint-roller-shape-of-the-tool:before { content: "\f1e3"; }
.flaticon-paintbrush-diagonal-artistic-tool-symbol:before { content: "\f1e4"; }
.flaticon-paperclip-filled-tool:before { content: "\f1e5"; }
.flaticon-paragraph-sign:before { content: "\f1e6"; }
.flaticon-password-key:before { content: "\f1e7"; }
.flaticon-password-symbol-of-key-silhouette-in-diagonal-position:before { content: "\f1e8"; }
.flaticon-pause:before { content: "\f1e9"; }
.flaticon-pause-two-lines:before { content: "\f1ea"; }
.flaticon-pen-filled-diagonal-writing-tool:before { content: "\f1eb"; }
.flaticon-pencil-and-filled-paper-sheet:before { content: "\f1ec"; }
.flaticon-pencil-and-ruler-in-vertical-position:before { content: "\f1ed"; }
.flaticon-pencil-and-ruler-in-vertical-position-1:before { content: "\f1ee"; }
.flaticon-pencil-diagonal-filled-writing-tool:before { content: "\f1ef"; }
.flaticon-person-silhouette:before { content: "\f1f0"; }
.flaticon-personal-card:before { content: "\f1f1"; }
.flaticon-photo-camera:before { content: "\f1f2"; }
.flaticon-photogram-filled-rectangular:before { content: "\f1f3"; }
.flaticon-picture-square:before { content: "\f1f4"; }
.flaticon-pin-diagonal-tool-of-black-shape:before { content: "\f1f5"; }
.flaticon-pin-filled-vertical-tool:before { content: "\f1f6"; }
.flaticon-placeholder-filled-tool-shape-for-maps:before { content: "\f1f7"; }
.flaticon-play-arrow:before { content: "\f1f8"; }
.flaticon-plug-connector:before { content: "\f1f9"; }
.flaticon-plus-magnification-zoom:before { content: "\f1fa"; }
.flaticon-plus-minus-signs-in-rectangular-interface-button:before { content: "\f1fb"; }
.flaticon-point-of-writing-tool:before { content: "\f1fc"; }
.flaticon-portfolio-filled-tool:before { content: "\f1fd"; }
.flaticon-portfolio-filled-tool-1:before { content: "\f1fe"; }
.flaticon-power-on:before { content: "\f1ff"; }
.flaticon-presentation-podium-with-a-microphone:before { content: "\f200"; }
.flaticon-printer-filled-tool-for-print-button:before { content: "\f201"; }
.flaticon-prize-badge-of-sports-or-education:before { content: "\f202"; }
.flaticon-prohibition-signal:before { content: "\f203"; }
.flaticon-puzzle-black-piece-shape:before { content: "\f204"; }
.flaticon-question-mark:before { content: "\f205"; }
.flaticon-question-sign-in-a-circle:before { content: "\f206"; }
.flaticon-rectangular-image-and-text-lines-aligned-to-left:before { content: "\f207"; }
.flaticon-rectangular-shape-of-a-rectangle-with-four-angles:before { content: "\f208"; }
.flaticon-rectangular-shape-with-two-lines:before { content: "\f209"; }
.flaticon-recycle-bin-filled-tool:before { content: "\f20a"; }
.flaticon-reply-email:before { content: "\f20b"; }
.flaticon-returning-curved-right-arrow:before { content: "\f20c"; }
.flaticon-rewind:before { content: "\f20d"; }
.flaticon-rewind-to-beginning-with-left-arrow:before { content: "\f20e"; }
.flaticon-right-and-left-arrows-square-button:before { content: "\f20f"; }
.flaticon-right-and-left-arrows-with-vertical-line-in-the-middle:before { content: "\f210"; }
.flaticon-right-and-left-arrows-with-vertical-line-separation:before { content: "\f211"; }
.flaticon-right-and-left-directional-arrows-signals-on-a-pole:before { content: "\f212"; }
.flaticon-right-arrow:before { content: "\f213"; }
.flaticon-right-arrow-angle:before { content: "\f214"; }
.flaticon-right-arrow-in-circular-button:before { content: "\f215"; }
.flaticon-right-arrow-in-square-button-outline:before { content: "\f216"; }
.flaticon-right-arrow-signal-on-a-pole:before { content: "\f217"; }
.flaticon-right-arrow-signal-on-a-pole-1:before { content: "\f218"; }
.flaticon-right-arrow-symbol-pointing-a-vertical-line:before { content: "\f219"; }
.flaticon-right-quote-sign:before { content: "\f21a"; }
.flaticon-right-text-alignment:before { content: "\f21b"; }
.flaticon-robot-rectangular-head-symbol:before { content: "\f21c"; }
.flaticon-rocket-space-ship-in-diagonal:before { content: "\f21d"; }
.flaticon-royal-crown:before { content: "\f21e"; }
.flaticon-rss-feed-in-square:before { content: "\f21f"; }
.flaticon-rss-feed-symbol:before { content: "\f220"; }
.flaticon-ruler-or-comb-diagonal-tool:before { content: "\f221"; }
.flaticon-sad-black-emoticon-face:before { content: "\f222"; }
.flaticon-sand-clock:before { content: "\f223"; }
.flaticon-search:before { content: "\f224"; }
.flaticon-send-email:before { content: "\f225"; }
.flaticon-settings-symbol-of-a-cross-of-tools:before { content: "\f226"; }
.flaticon-share:before { content: "\f227"; }
.flaticon-shield:before { content: "\f228"; }
.flaticon-shopping-cart-filled-commercial-symbol:before { content: "\f229"; }
.flaticon-shopping-cart-filled-tool:before { content: "\f22a"; }
.flaticon-slash-or-division-sign-in-square-outlined-key:before { content: "\f22b"; }
.flaticon-smiling-face-in-circular-speech-balloon:before { content: "\f22c"; }
.flaticon-speaker-filled-audio-tool:before { content: "\f22d"; }
.flaticon-speech-filled-rectangular-bubble-with-text-lines:before { content: "\f22e"; }
.flaticon-speed-control-of-semicircular-shape:before { content: "\f22f"; }
.flaticon-speedometer-circular-tool:before { content: "\f230"; }
.flaticon-sport:before { content: "\f231"; }
.flaticon-squirrel-filled-silhouette-from-side-view:before { content: "\f232"; }
.flaticon-star-badge:before { content: "\f233"; }
.flaticon-statistics-in-bars-graphic:before { content: "\f234"; }
.flaticon-stop-hand-gesture:before { content: "\f235"; }
.flaticon-stop-square-filled-button:before { content: "\f236"; }
.flaticon-straight-right-arrow:before { content: "\f237"; }
.flaticon-straight-up-arrow:before { content: "\f238"; }
.flaticon-sunny-day-or-sun-weather:before { content: "\f239"; }
.flaticon-switch-at-center:before { content: "\f23a"; }
.flaticon-tablet:before { content: "\f23b"; }
.flaticon-tag-commercial-tool:before { content: "\f23c"; }
.flaticon-tag-filled-commercial-tool:before { content: "\f23d"; }
.flaticon-team:before { content: "\f23e"; }
.flaticon-telescope:before { content: "\f23f"; }
.flaticon-text-black-file:before { content: "\f240"; }
.flaticon-text-option:before { content: "\f241"; }
.flaticon-text-options-letters:before { content: "\f242"; }
.flaticon-three-circles-outlines-connected-by-lines:before { content: "\f243"; }
.flaticon-three-dots-rounded-button:before { content: "\f244"; }
.flaticon-three-lines-of-levels-lights-squares:before { content: "\f245"; }
.flaticon-three-rectangles-outlines-in-perspective:before { content: "\f246"; }
.flaticon-ticket-half-symbol-with-a-star:before { content: "\f247"; }
.flaticon-tower-with-signal-emission:before { content: "\f248"; }
.flaticon-triangle-of-down-left-arrow-outline:before { content: "\f249"; }
.flaticon-triangular-black-right-arrow:before { content: "\f24a"; }
.flaticon-triangular-filled-up-arrow:before { content: "\f24b"; }
.flaticon-triple-horizontally-aligned-lines:before { content: "\f24c"; }
.flaticon-trophy-cup-black-shape:before { content: "\f24d"; }
.flaticon-truck-filled-side-view-shape-pointing-to-right:before { content: "\f24e"; }
.flaticon-turning-left-arrow:before { content: "\f24f"; }
.flaticon-turning-to-right:before { content: "\f250"; }
.flaticon-two-arrows-and-a-cross:before { content: "\f251"; }
.flaticon-two-arrows-fast-forward:before { content: "\f252"; }
.flaticon-two-books-sizes-outlined-symbol:before { content: "\f253"; }
.flaticon-two-filled-folders-of-different-sizes:before { content: "\f254"; }
.flaticon-two-filled-hard-disks-stack:before { content: "\f255"; }
.flaticon-two-filled-overlapped-rectangles:before { content: "\f256"; }
.flaticon-two-filled-rectangular-parts:before { content: "\f257"; }
.flaticon-two-straight-arrows-square-shape-of-exchange:before { content: "\f258"; }
.flaticon-unblocked-rectangular-filled-padlock:before { content: "\f259"; }
.flaticon-underlined-text:before { content: "\f25a"; }
.flaticon-up-and-down-arrows-with-a-line-in-the-middle:before { content: "\f25b"; }
.flaticon-up-and-down-opposite-double-arrows-side-by-side:before { content: "\f25c"; }
.flaticon-up-arrow:before { content: "\f25d"; }
.flaticon-up-arrow-1:before { content: "\f25e"; }
.flaticon-up-arrow-angle:before { content: "\f25f"; }
.flaticon-up-arrow-circular-button:before { content: "\f260"; }
.flaticon-up-arrow-with-a-line:before { content: "\f261"; }
.flaticon-up-right-arrow:before { content: "\f262"; }
.flaticon-upload:before { content: "\f263"; }
.flaticon-upload-a-book:before { content: "\f264"; }
.flaticon-upload-email:before { content: "\f265"; }
.flaticon-upload-with-up-arrow-into-a-cloud:before { content: "\f266"; }
.flaticon-uploading-book:before { content: "\f267"; }
.flaticon-upper-left-arrow:before { content: "\f268"; }
.flaticon-upper-right-arrow-black-symbol:before { content: "\f269"; }
.flaticon-upper-right-arrow-in-square-button-outline:before { content: "\f26a"; }
.flaticon-user-filled-shape:before { content: "\f26b"; }
.flaticon-verification-sign:before { content: "\f26c"; }
.flaticon-visible-eye:before { content: "\f26d"; }
.flaticon-voice:before { content: "\f26e"; }
.flaticon-wallet-filled-commercial-tool:before { content: "\f26f"; }
.flaticon-warning-exclamation-sign-in-a-circle-with-verification-mark:before { content: "\f270"; }
.flaticon-warning-exclamation-sign-in-filled-triangle:before { content: "\f271"; }
.flaticon-warning-signal-of-exclamation-sign-in-octagonal-outlined-shape:before { content: "\f272"; }
.flaticon-web-page-with-right-column:before { content: "\f273"; }
.flaticon-window:before { content: "\f274"; }
.flaticon-zipped-file-filled-with-a-zip:before { content: "\f275"; }
.flaticon-sport-aim:before { content: "\f276"; }
.flaticon-sport-archery-board:before { content: "\f277"; }
.flaticon-sport-archery:before { content: "\f278"; }
.flaticon-sport-badge:before { content: "\f279"; }
.flaticon-sport-baseball-bat:before { content: "\f27a"; }
.flaticon-sport-basketball-ball:before { content: "\f27b"; }
.flaticon-sport-basketball-field:before { content: "\f27c"; }
.flaticon-sport-bicycle:before { content: "\f27d"; }
.flaticon-sport-billiard-ball:before { content: "\f27e"; }
.flaticon-sport-bowling:before { content: "\f27f"; }
.flaticon-sport-boxing-ring:before { content: "\f280"; }
.flaticon-sport-boxing-glove:before { content: "\f281"; }
.flaticon-sport-calendar:before { content: "\f282"; }
.flaticon-sport-certificate:before { content: "\f283"; }
.flaticon-sport-cricket:before { content: "\f284"; }
.flaticon-sport-crown:before { content: "\f285"; }
.flaticon-sport-curling:before { content: "\f286"; }
.flaticon-sport-dart:before { content: "\f287"; }
.flaticon-sport-dices:before { content: "\f288"; }
.flaticon-sport-diet-food:before { content: "\f289"; }
.flaticon-sport-dumbbell:before { content: "\f28a"; }
.flaticon-sport-fencing:before { content: "\f28b"; }
.flaticon-sport-finish-flag:before { content: "\f28c"; }
.flaticon-sport-fins:before { content: "\f28d"; }
.flaticon-sport-first:before { content: "\f28e"; }
.flaticon-sport-fishing:before { content: "\f28f"; }
.flaticon-sport-flag:before { content: "\f290"; }
.flaticon-sport-football:before { content: "\f291"; }
.flaticon-sport-goal:before { content: "\f292"; }
.flaticon-sport-golf-ball:before { content: "\f293"; }
.flaticon-sport-golf-ball:before { content: "\f294"; }
.flaticon-sport-gymnastics:before { content: "\f295"; }
.flaticon-sport-handgrip:before { content: "\f296"; }
.flaticon-sport-health-report:before { content: "\f297"; }
.flaticon-sport-helmet:before { content: "\f298"; }
.flaticon-sport-hoop:before { content: "\f299"; }
.flaticon-sport-ice-hockey:before { content: "\f29a"; }
.flaticon-sport-ice-skate:before { content: "\f29b"; }
.flaticon-sport-basketball-jersey:before { content: "\f29c"; }
.flaticon-sport-jigsaw:before { content: "\f29d"; }
.flaticon-sport-jumping-rope:before { content: "\f29e"; }
.flaticon-sport-karate:before { content: "\f29f"; }
.flaticon-sport-kayak:before { content: "\f2a0"; }
.flaticon-sport-kite:before { content: "\f2a1"; }
.flaticon-sport-location-pin:before { content: "\f2a2"; }
.flaticon-sport-mat:before { content: "\f2a3"; }
.flaticon-sport-medal:before { content: "\f2a4"; }
.flaticon-sport-medical-kit:before { content: "\f2a5"; }
.flaticon-sport-megaphone:before { content: "\f2a6"; }
.flaticon-sport-mobile-phone:before { content: "\f2a7"; }
.flaticon-sport-player:before { content: "\f2a8"; }
.flaticon-sport-podium:before { content: "\f2a9"; }
.flaticon-sport-protein-powder:before { content: "\f2aa"; }
.flaticon-sport-punching-ball:before { content: "\f2ab"; }
.flaticon-sport-punching-ball:before { content: "\f2ac"; }
.flaticon-sport-rafting:before { content: "\f2ad"; }
.flaticon-sport-reward:before { content: "\f2ae"; }
.flaticon-sport-roller-skate:before { content: "\f2af"; }
.flaticon-sport-rugby-helmet:before { content: "\f2b0"; }
.flaticon-sport-sail-boat:before { content: "\f2b1"; }
.flaticon-sport-second:before { content: "\f2b2"; }
.flaticon-sport-shoe:before { content: "\f2b3"; }
.flaticon-sport-shorts:before { content: "\f2b4"; }
.flaticon-sport-shuttlecock:before { content: "\f2b5"; }
.flaticon-sport-skateboard:before { content: "\f2b6"; }
.flaticon-sport-ski:before { content: "\f2b7"; }
.flaticon-sport-smartwatch:before { content: "\f2b8"; }
.flaticon-sport-soccer-field:before { content: "\f2b9"; }
.flaticon-sport-soccer-ball:before { content: "\f2ba"; }
.flaticon-sport-soccer-jersey:before { content: "\f2bb"; }
.flaticon-sport-softball:before { content: "\f2bc"; }
.flaticon-sport-sport-bag:before { content: "\f2bd"; }
.flaticon-sport-stationary-bike:before { content: "\f2be"; }
.flaticon-sport-steering-wheel:before { content: "\f2bf"; }
.flaticon-sport-stopwatch:before { content: "\f2c0"; }
.flaticon-sport-surfboard:before { content: "\f2c1"; }
.flaticon-sport-swimming-cap:before { content: "\f2c2"; }
.flaticon-sport-swimming-pool:before { content: "\f2c3"; }
.flaticon-sport-table-tennis:before { content: "\f2c4"; }
.flaticon-sport-tank-top:before { content: "\f2c5"; }
.flaticon-sport-tennis-ball:before { content: "\f2c6"; }
.flaticon-sport-tennis-court:before { content: "\f2c7"; }
.flaticon-sport-tennis-racket:before { content: "\f2c8"; }
.flaticon-sport-third:before { content: "\f2c9"; }
.flaticon-sport-treadmill:before { content: "\f2ca"; }
.flaticon-sport-trophy:before { content: "\f2cb"; }
.flaticon-sport-volleyball-ball:before { content: "\f2cc"; }
.flaticon-sport-weight-scale:before { content: "\f2cd"; }
.flaticon-sport-whistle:before { content: "\f2ce"; }
.flaticon-user-add-group:before { content: "\f000"; }
.flaticon-user-add-user:before { content: "\f001"; }
.flaticon-user-block-user:before { content: "\f002"; }
.flaticon-user-check:before { content: "\f003"; }
.flaticon-user-contacts:before { content: "\f004"; }
.flaticon-user-delete-user:before { content: "\f005"; }
.flaticon-user-friends:before { content: "\f006"; }
.flaticon-user-global:before { content: "\f007"; }
.flaticon-user-group:before { content: "\f008"; }
.flaticon-user-home:before { content: "\f009"; }
.flaticon-user-hourglass:before { content: "\f00a"; }
.flaticon-user-identity-card:before { content: "\f00b"; }
.flaticon-user-information:before { content: "\f00c"; }
.flaticon-user-key:before { content: "\f00d"; }
.flaticon-user-location:before { content: "\f00e"; }
.flaticon-user-lock:before { content: "\f00f"; }
.flaticon-user-love:before { content: "\f010"; }
.flaticon-user-mail:before { content: "\f011"; }
.flaticon-user-money:before { content: "\f012"; }
.flaticon-user-network:before { content: "\f013"; }
.flaticon-user-notification:before { content: "\f014"; }
.flaticon-user-organization:before { content: "\f015"; }
.flaticon-user-personal-computer:before { content: "\f016"; }
.flaticon-user-personal-data:before { content: "\f017"; }
.flaticon-user-personal-file:before { content: "\f018"; }
.flaticon-user-personal-identification:before { content: "\f019"; }
.flaticon-user-rate:before { content: "\f01a"; }
.flaticon-user-refresh:before { content: "\f01b"; }
.flaticon-user-search:before { content: "\f01c"; }
.flaticon-user-setting:before { content: "\f01d"; }
.flaticon-user-share:before { content: "\f01e"; }
.flaticon-user-shield:before { content: "\f01f"; }
.flaticon-user-social-network:before { content: "\f020"; }
.flaticon-user-star:before { content: "\f021"; }
.flaticon-user-switch:before { content: "\f022"; }
.flaticon-user-talk:before { content: "\f023"; }
.flaticon-user-target:before { content: "\f024"; }
.flaticon-user-team:before { content: "\f025"; }
.flaticon-user-time:before { content: "\f026"; }
.flaticon-user-unlock:before { content: "\f027"; }
.flaticon-user-database:before { content: "\f028"; }
.flaticon-user-user:before { content: "\f029"; }
.flaticon-user-edit:before { content: "\f02a"; }
.flaticon-user-profile:before { content: "\f02b"; }
.flaticon-user-profile:before { content: "\f02c"; }
.flaticon-user-profile:before { content: "\f02d"; }
.flaticon-user-profile:before { content: "\f02e"; }
.flaticon-user-user:before { content: "\f02f"; }
.flaticon-user-user:before { content: "\f030"; }
.flaticon-user-warning:before { content: "\f031"; }
