.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* truncate to 4 lines */
  -webkit-line-clamp: 4;
}

.border-selected {
    border-color: #0d6efd;
    border-width: 5px;
    border-radius: 15px;
    border-style: solid;
}

.border-selected-primary {
    border-color: #0d6efd;
    border-width: 5px;
    border-radius: 15px;
    border-style: solid;
}

.border-selected-warning {
    border-color: #ffc107;
    border-width: 5px;
    border-radius: 15px;
    border-style: solid;
}

.border-selected-danger {
    border-color: #dc3545;
    border-width: 5px;
    border-radius: 15px;
    border-style: solid;
}

.border-sm-selected {
    border-color: #0d6efd;
    border-width: 2px;
    border-radius: 5px;
}

.border-sm-selected-primary {
    border-color: #0d6efd;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
}

.border-sm-selected-warning {
    border-color: #ffc107;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
}

.border-sm-selected-danger {
    border-color: #dc3545;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
}

.border-sm-selected-hoverable {
    border-color: #ffffff;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
}

.border-sm-selected-hoverable:hover {
    border-color: #ced4da;
    border-width: 2px;
    border-radius: 5px;
    border-style: solid;
}

.bg-fixed {
    background-color: #0d6efd;
}

.dropdown-toggle::after {
    display: none !important; 
  }

.errors {
    color: red;
}

.horizontal-scroll {
    overflow-x: scroll;
}

.vertical-scroll {
    overflow-y: scroll;
}

.user-message-content h1 {
    font-size: 1.5rem;
}

.user-message-content h2 {
    font-size: 1.4rem;
}

.user-message-content h3 {
    font-size: 1.3rem;
}

.user-message-content h4 {
    font-size: 1.2rem;
}

.user-message-content h5 {
    font-size: 1.1rem;
}

.chat-bubble {
    position: relative;
    display: block;
    padding: 0.25rem 0.25rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.5rem;
}

.cursor-default {
    cursor: default;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-wait {
    cursor: wait;
}

.cursor-progress {
    cursor: progress;
}

.cursor-text {
    cursor: text;
}

.cursor-help {
    cursor: help;
}

.cursor-copy {
    cursor: copy;
}

.cursor-zoom-in {
    cursor: zoom-in;
}

.cursor-zoom-out {
    cursor: zoom-out;
}
